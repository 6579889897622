<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          style="width: 200px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Kode OTP
          </b-card-title>
          <b-card-text class="mb-2">
            Masukan kode OTP yang telah dikirim ke email
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2 text-center"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label=""
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                >
                  <OtpInput
                    ref="otpInput"
                    input-classes="form-control form-custom-bg otp"
                    separator="-"
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-complete="isCompleteOtp"
                    @on-change="isCompleteOtp"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small v-if="errors" class="text-danger">{{ errors }}</small>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="is_ok_otp"
              >
                Verifikasi
              </b-button>
              <br>
              <small class="text-primary">
                <vue-countdown-timer
                  :start-time="'2021-06-04 10:00:00'"
                  :end-time="end_time"
                  :interval="1000"
                  :day-txt="null"
                  :hour-txt="null"
                  :seconds-txt="''"
                  label-position="begin">
                  <template slot="start-label">
                    Kirim ulang OTP setelah
                  </template>
                  <template slot="countdown" slot-scope="scope">
                    <span>{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}<span>{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}
                  </template>
                  <template slot="end-text">
                    <a href="javascript:;" @click="kirimUlangOtp()">Kirim ulang OTP</a>
                  </template>
                </vue-countdown-timer>
              </small>
              <!-- <vue-countdown-timer
                :start-time="'2021-06-04 10:00:00'"
                :end-time="1622776303"
                :interval="1000"
                :start-label="'Until start:'"
                :end-label="'Until end:'"
                label-position="begin"
                :end-text="'Event ended!'"
                :day-txt="'days'"
                :hour-txt="'hours'"
                :minutes-txt="'minutes'"
                :seconds-txt="'seconds'">
              </vue-countdown-timer> -->
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@axios"
import OtpInput from "@bachdgvn/vue-otp-input";
import { $themeConfig } from '@themeConfig'

// App Name
const { appName, appLogoImage } = $themeConfig.app

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    OtpInput
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      is_ok_otp: true,
      no_otp: null,
      errors: null,
      // validation
      required,
      email,
      end_time: null,
      appName,
      appLogoImage
    }
  },
  mounted() {
    let _ = this
    _.restartCounter()
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      let _ = this
      _.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios.post('account/reset-password', { otp: _.no_otp }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('resetPassToken')}` 
                }
            })
            .then(resp => {
              _.$toast({
                component: ToastificationContent,
                props: {
                  title: resp.data.message,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              localStorage.setItem('resetPassToken', resp.data.data.resetPassToken)
            })
            .then(() => _.$router.push({name: 'auth-reset-password-v2'}))
            .catch(err => {
              _.errors = err.response.data.message.errors ? err.response.data.message.errors[0].msg : err.response.data.message
            })
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'This is for UI purpose only.',
          //     icon: 'EditIcon',
          //     variant: 'success',
          //   },
          // })
        }
      })
    },
    isCompleteOtp() {
        let _ = this
        if ( _.$refs.otpInput === undefined ) {
            _.is_ok_otp = true
        } else {
            _.no_otp = _.$refs.otpInput.otp.join('')

            _.is_ok_otp = _.no_otp.length < 6    
        }
        
    },
    kirimUlangOtp() {
      let _ = this
      axios.post('account/resend-otp', {}, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('resetPassToken')}` 
          }
      })
      .then(resp => {
        localStorage.setItem('resetPassToken', resp.data.data.resetPassToken)
        _.$toast({
          component: ToastificationContent,
          props: {
            title: resp.data.message,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
      .then(() => _.restartCounter())
      .catch(err => {
        var errors = err.response.data.message.errors ? err.response.data.message.errors[0].msg : err.response.data.message
      })
    },
    restartCounter() {
      let _ = this
      var now = new Date();
      now.setSeconds(now.getSeconds() + 61); // timestamp
      now = new Date(now).getTime(); // Date object
      
      _.end_time = now
    },
    handleOnChange() {
      console.log('cek')
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
